import Image from 'next/image';

import React, {useEffect, useState} from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios from "axios";
import ReactMarkdown from 'react-markdown';

function Testimonial() {

    const [testimonials, setTestimonials] = useState<any>([]);

    const [expanded, setExpanded] = useState(false)

    const fetchTestimonials = async () => {
        try {
            const result = await axios.get(`${process.env.admin_url}/api/testimonials?populate=*`);
            setTestimonials(result?.data?.data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect( () => { fetchTestimonials(); }, []);

    return (
        <>
            <div className="quote-background">
                <div className="stars"></div>
                <div className="stars"></div>
                <div className="stars"></div>
                <div className="stars"></div>
                <div className="stars"></div>
            </div>
            <div>
                <h1 className="md:text-header text-header-mbl font-sourcesans relative text-center md:hidden">
                    <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[100px] md:after:top-[65px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500 after:rounded">
                        <span className="text-white mr-2">
                            What our client says
                        </span>
                        <span className="text-orange mr-3"> about us</span>
                    </span>
                </h1>
                <h1 className="md:text-header text-header-mbl font-sourcesans relative text-center hidden md:block">
                    <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[50px] md:after:top-[65px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500 after:rounded">
                        <span className="text-white mr-2">What our</span>
                        <span className="text-orange mr-3">client</span>
                        <span className="text-white mr-2">says about us</span>
                    </span>
                </h1>
            </div>
            <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                {testimonials.map((testimonial: any, index: number) => (
                    <SwiperSlide key={index}>
                        <div
                            key={index}
                            className="relative lg:flex lg:items-center mx-auto mt-16 lg:max-w-4xl py-16"
                        >
                            <div className="relative lg:ml-24">
                                <div className="relative bg-white p-10 lg:pl-36 rounded-[5px_50px_5px_50px]">
                                    <div className="mb-3">
                                        <div className="flex">
                                            <div className="flex-shrink-0 lg:hidden">
                                                <Image
                                                    className="h-56 w-56 rounded-full xl:h-56 xl:w-56"
                                                    src={
                                                        process.env.admin_url +
                                                        testimonial?.attributes
                                                            ?.image?.data
                                                            ?.attributes?.url
                                                    }
                                                    alt="image"
                                                    height={50}
                                                    width={50}
                                                />
                                            </div>
                                            <div className="ml-4 lg:ml-0">
                                                <div className="text-h3 font-montserratB text-primary-500">
                                                    {
                                                        testimonial?.attributes
                                                            ?.name
                                                    }
                                                </div>
                                                <div className="text-lg font-montserratR text-primary-500">
                                                    {
                                                        testimonial?.attributes
                                                            ?.title
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-primary-500 py-3 text-body-mbl md:text-[18px]">
                                    {expanded? testimonial?.attributes?.description :
                                    testimonial?.attributes?.description.substring(0,270)}

                                    {expanded? <span onClick={() => setExpanded(!expanded)} className='text-secondary-500 text-body-mbl cursor-pointer'> See less</span>:
                                    <span onClick={() => setExpanded(!expanded)} className='text-secondary-500 text-body-mbl cursor-pointer'> ...See more</span>}
                                        {/* <ReactMarkdown>
                                            {testimonial?.attributes
                                                ?.description?.length > 200
                                                ? testimonial?.attributes?.description.substring(
                                                      0,
                                                      200
                                                  ) + '...'
                                                : testimonial?.attributes?.description.substring(
                                                      0,
                                                      200
                                                  )}
                                        </ReactMarkdown> */}
                                    </div>
                                </div>
                                <svg
                                    className="absolute top-0 right-0 transform -translate-x-8 -translate-y-12 text-secondary-500"
                                    width="101"
                                    height="80"
                                    viewBox="0 0 101 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M63.9634 0.980469H100.049V26.7925C100.049 37.2371 99.1499 45.4559 97.3521 51.4487C95.4686 57.5272 92.0441 62.9635 87.0786 67.7578C82.1131 72.5521 75.7778 76.319 68.0728 79.0586L61.0098 64.1621C68.2012 61.765 73.3807 58.4261 76.5483 54.1455C79.6304 49.8649 81.257 44.1717 81.4282 37.0659H63.9634V0.980469ZM3.73535 0.980469H39.8208V26.7925C39.8208 37.3228 38.9219 45.5843 37.124 51.5771C35.2406 57.57 31.8161 62.9635 26.8506 67.7578C21.7995 72.5521 15.4642 76.319 7.84473 79.0586L0.781738 64.1621C7.97314 61.765 13.1527 58.4261 16.3203 54.1455C19.4023 49.8649 21.029 44.1717 21.2002 37.0659H3.73535V0.980469Z"
                                        fill="#F36012"
                                    />
                                </svg>
                            </div>
                            <div className="hidden lg:block lg:absolute top-1/5 left-0 lg:flex-shrink-0">
                                <Image
                                    className="h-56 w-56 rounded-full xl:h-56 xl:w-56"
                                    src={
                                        process.env.admin_url +
                                        testimonial?.attributes?.image?.data
                                            ?.attributes?.url
                                    }
                                    alt="image"
                                    height={180}
                                    width={180}
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default Testimonial;